import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { fbDb, fbAuth } from '../../app/firebase'
import { addDoc, collection, doc, getDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'

// Utility function to convert Firestore Timestamps to ISO strings
const convertTimestamps = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key].toDate === 'function') {
      obj[key] = obj[key].toDate().toISOString()
    } else if (obj[key] && typeof obj[key] === 'object') {
      convertTimestamps(obj[key]) // Recursive call for nested objects
    }
  })
  return obj
}

export const createVisit = createAsyncThunk(
  'visits/createVisit',
  async ({ newVisit, user }, thunkAPI) => {
    try {
      const visitId = await createVisitDocument(newVisit, user)
      return visitId
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

// Function to create a visit document
const createVisitDocument = async (newVisit, user) => {
  try {
    if (user && user.uid) {
      const visitsCollectionRef = collection(fbDb, 'visits')
      const visitDocRef = await addDoc(visitsCollectionRef, {
        ...newVisit,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: user.uid,
        updatedBy: user.uid,
      })

      // Check if currentUser is authenticated
      let currentUser = fbAuth.currentUser
      if (!currentUser) {
        // Wait for the user to be authenticated
        await new Promise((resolve) => {
          const unsubscribe = fbAuth.onAuthStateChanged((user) => {
            if (user) {
              currentUser = user
              unsubscribe()
              resolve()
            }
          })
        })
      }

      // Update the document with the generated visitId and logging info
      if (currentUser && currentUser.uid) {
        await updateDoc(visitDocRef, {
          visitId: visitDocRef.id,
          updatedBy: currentUser.uid, // Log the update with the current authenticated user's UID
        })

        console.log('Visit document successfully created with ID:', visitDocRef.id)

        // Return the visit ID
        return visitDocRef.id
      } else {
        console.error('No current user is authenticated for logging purposes.')
        return null
      }
    } else {
      console.error('No user is currently authenticated or user UID is missing.')
      return null // Return null if user is not authenticated or UID is missing
    }
  } catch (error) {
    console.error('Error creating visit document:', error)
    throw error // Throw the error to let Redux Toolkit handle it
  }
}

export const fetchVisitForChat = createAsyncThunk(
  'visits/fetchVisitForChat',
  async (visitId, { rejectWithValue }) => {
    try {
      const visitDocRef = doc(fbDb, 'visits', visitId);
      const docSnap = await getDoc(visitDocRef);

      if (!docSnap.exists()) {
        return rejectWithValue('Visit not found');
      }

      // 1) If the doc exists, get the full data
      let visitData = docSnap.data();
      visitData.visitId = docSnap.id;
      // 4) Return the entire doc with all fields
      return visitData;
    } catch (error) {
      console.error('Error fetching visit for chat:', error);
      return rejectWithValue(error.message);
    }
  }
);


// The "subscribeToVisitChat" thunk-like function
export const subscribeToVisitChat = (visitId) => (dispatch, getState) => {
  // 1) If there's an existing subscription, clear it
  dispatch(clearSubscription());

  if (!visitId) return;

  // 2) Setup onSnapshot for real-time updates
  const visitRef = doc(fbDb, 'visits', visitId);
  const unsub = onSnapshot(
    visitRef,
    (snap) => {
      if (snap.exists()) {
        let data = snap.data();
       
        dispatch(
          updateCurrentVisitForChat({
            ...data,
            visitId: snap.id
          })
        );
      }
    },
    (error) => {
      console.error('Error in subscribeToVisitChat:', error);
    }
  );

  // 3) Store the unsubscribe so we can stop later
  dispatch(setUnsubscribe(unsub));
};

// Define your visit slice
const visitSlice = createSlice({
  name: 'visits',
  initialState: {
    visits: [],
    currentVisitForChat: null,
    currentVisitId: null,
    isSubscribed: false,
    unsubscribe: null ,
    workflows: {
      'Follow-Up': { enrollment: true, intakeForm: false, UDS: 'optional' },
      'Sample Only': { enrollment: true, intakeForm: false, UDS: 'required' },
      Restart: { enrollment: true, intakeForm: false, UDS: 'required' },
      New: { enrollment: true, intakeForm: true, UDS: 'required' },
      Transfer: { enrollment: true, intakeForm: true, UDS: 'required' },
      'Patient Request': { enrollment: true, intakeForm: false, UDS: false },
      'Check-In Only': { enrollment: true, intakeForm: false, UDS: false },
    },
    currentVisitType: null,
    loading: false,
    error: null,
  },
  reducers: {
    // Define additional reducers here if needed
    updateVisits: (state, action) => {
      state.visits = action.payload
    },
    setCurrentVisitId(state, action) {
      state.currentVisitId = action.payload
    },
    setCurrentVisitType(state, action) {
      state.currentVisitType = action.payload
    },
    updateCurrentVisitForChat: (state, action) => {
      state.currentVisitForChat = action.payload
    },
    setUnsubscribe: (state, action) => {
      // store the unsubscribe function
      state.unsubscribe = action.payload;
    },
    clearSubscription: (state) => {
      // call the unsub if it exists
      if (state.unsubscribe) {
        state.unsubscribe();
        state.unsubscribe = null;
      }
      state.currentVisitForChat = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVisit.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createVisit.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createVisit.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchVisitForChat.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchVisitForChat.fulfilled, (state, action) => {
        state.loading = false
        state.currentVisitForChat = action.payload
      })
      .addCase(fetchVisitForChat.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload // Use payload for the error message
      })
  },
})

export const { setCurrentVisitId, setCurrentVisitType, updateCurrentVisitForChat,setUnsubscribe, clearSubscription } =
  visitSlice.actions

export const selectVisitLoading = (state) => state.visits.loading
export const updateVisits = (state) => state.visits.visits
export const selectCurrentVisitForChat = (state) => state.visits.currentVisitForChat
export const selectCurrentWorkflow = createSelector(
  [(state) => state.visits.currentVisitType, (state) => state.visits.workflows],
  (currentVisitType, workflows) => (currentVisitType ? workflows[currentVisitType] : null)
)

export default visitSlice.reducer
