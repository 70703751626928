export const firebaseConfig= {
    "apiKey": "AIzaSyBoQLkPRwvixSHbMPGx6r4scNIz88H-tvg",
    "authDomain": "truenorth-production.firebaseapp.com",
    "projectId": "truenorth-production",
    "storageBucket": "truenorth-production.firebasestorage.app",
    "messagingSenderId": "11182580643",
    "appId": "1:11182580643:web:ff3606034e74b5e9dff016",
    "measurementId": "G-3XEKCNYZBL",
    "serviceAccountId": "firebase-adminsdk-fbsvc@truenorth-production.iam.gserviceaccount.com",
    "credential": "cert(serviceAccount)",
    "hubspotApiBearerToken" : "pat-na1-1fa325c9-57ba-45fb-a6e9-6a2415746fa7",
    "secret" : "Al1YurBas3sali9ui@p"
};